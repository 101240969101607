import React from 'react';
import about from '../img/heads/exp2.png';
import Container from 'react-bootstrap/Container';
import '../css/Proyectid.css';
import {useParams} from 'react-router-dom'
let json = require('../document/proyecto.json');


const  ProyectId = () => {

    const {id} = useParams();
    console.log({id});
    console.log({id});


    return ( 
    <div className="ProyectId">
        <img className='img-about' src={about} alt="about"></img>
        
        <Container className="containerproyectoid">

            <h2>Experiencia realizada</h2>
            <hr className='separator'/>

            <h3>{json[id].name}</h3>

           
           
            <h4>Actividades</h4>
           
            <div className='animeActividades'>
            <ol>
            {
            
                json[id].descripcion.map((item, index) => {
                                                    
                    return(
                        
                            <li key={index}>
                                {item.descrip}
                            </li>
                    )
                
                })
            
            
            }
            </ol>
            </div>
           

            <h5>Cliente</h5>
            <h4>{json[id].Cliente}</h4>
            <p>{json[id].fecha}</p>
            <p>{json[id].pais}</p>

        </Container>
       
    </div>
    );
}

export default ProyectId