import React from 'react';
import img1 from '../img/clientes/1.png'
import img2 from '../img/clientes/2.png'
import img3 from '../img/clientes/3.png'
import img4 from '../img/clientes/4.png'
import img5 from '../img/clientes/5.png'
import img6 from '../img/clientes/6.png'
import img7 from '../img/clientes/7.png'
import img8 from '../img/clientes/8.png'
import img9 from '../img/clientes/9.png'
import img10 from '../img/clientes/10.png'
import img11 from '../img/clientes/11.png'
import img12 from '../img/clientes/12.png'
import img13 from '../img/clientes/13.png'
import img14 from '../img/clientes/14.png'


const  Clientes = () => {

return(

    <div>
              <div className="div-slider">
                <div className="slider">
                    <div className="slide-track">
                        <div className="slide">
                            <img src={img1} alt="cliente1" />
                        </div>
                        <div className="slide">
                            <img src={img2} alt="cliente2" />
                        </div>
                        <div className="slide">
                        <img src={img3} alt="cliente2" />
                        </div>
                        <div className="slide">
                        <img src={img4} alt="cliente2" />
                        </div>
                        <div className="slide">
                        <img src={img5} alt="cliente2" />
                        </div>
                        <div className="slide">
                        <img src={img6} alt="cliente2" />
                        </div>
                        <div className="slide">
                            <img src={img7} alt="cliente1" />
                        </div>
                        <div className="slide">
                            <img src={img8} alt="cliente2" />
                        </div>
                        <div className="slide">
                        <img src={img9} alt="cliente2" />
                        </div>
                        <div className="slide">
                        <img src={img10} alt="cliente2" />
                        </div>
                        <div className="slide">
                        <img src={img11} alt="cliente2" />
                        </div>
                        <div className="slide">
                        <img src={img12} alt="cliente2" />
                        </div>
                        <div className="slide">
                        <img src={img13} alt="cliente2" />
                        </div>
                        <div className="slide">
                        <img src={img14} alt="cliente2" />
                        </div>
                        <div className="slide">
                            <img src={img1} alt="cliente1" />
                        </div>
                        <div className="slide">
                            <img src={img2} alt="cliente2" />
                        </div>
                    </div>
                </div>
            </div>
    </div>

)

}

export default Clientes;