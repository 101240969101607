import React, {useEffect, useState, Fragment } from 'react';
// import about from '../img/2.png';
import '../css/AcercaN.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from 'react-ga';



const  AcercaN = () => {

    useEffect(() => {
        ReactGA.pageview('/nosotros');
      }, []);
    
    return(
    
        <Fragment>

           
            <div className='img-nosotros'/>
           
            <Container className='container-about'>
                <Row className="justify-content-center">
                    <div className='ingresoizq'>
                        <h2>Acerca de nosotros</h2>
                        <hr className='separator'/>
                        <p>
                            Somos una organización especializada en la planificación y ejecución de proyectos técnicos y administrativos que logran un acompañamiento integral a las organizaciones y a sus diversos procesos de forma sistémica. 
                            Desde hace 10 años contamos con un grupo de profesionales interdisciplinarios que fortalecen acciones Administrativas, Ambientales y Sociales.  Nuestro equipo se compone de profesionales de alta calidad con experiencia y formación técnica actualizada, comprometidos y leales, quienes se enfocan siempre en el mejoramiento continuo, con decisiones eficientes para nuestros clientes.

                            Somos una organización innovadora y de calidad, distinguiéndonos por ser excelentes asesores administrativos y técnicos, implementando metodologías, estrategias y soluciones dinámicas y efectivas en el acompañamiento integral de quienes confían en nosotros. 
                        </p>
                       
                    </div>
                </Row>
                <Row className="justify-content-center">
                    <div className='ingresoder'>
                        <h2>Misión</h2>
                        <hr className='separator'/>

                        <p>
                            Ofrecer un servicio integral de calidad e innovación a quienes confían en nosotros, así como generar compromiso y valor en todas nuestras actividades para los diferentes grupos de interés.
                        </p>
                       
                    </div>
                </Row>
                <Row className="justify-content-center">
                <div className='ingresoizq'>
                    <h2>Visión</h2>
                    <hr className='separator'/>
                    <div className='texto-about'>
                        <p>
                            En Nexos Empresarial tenemos como visión ser la mejor empresa en calidad de servicio.
                        </p>
                    </div>
                    
                    </div>
                </Row>
            </Container>
          
         
        </Fragment>
    )
    
    }
    
    export default AcercaN;