import React from 'react';
import '../css/ProyInicioN.css';
import Container from 'react-bootstrap/Container';
// import icon from '../img/iconos/check.svg';
// import img from '../img/inicio/exp1.jpg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import 'bootstrap/dist/css/bootstrap.min.css';

const ProInicioN = () =>{

    return (

    <div className="ProinicioN">

        <Container className="containerproyincio">

            <Row>
                <h5>
                Contamos con experiencia nacional e internacional dispuesta a su servicio bridando soluciones integrales y oportunas a su organización
                </h5>
            </Row>
            <Row>
                <Col sm={12} md={6}>

                {/* <img className="imgexperiencia" src={img} /> */}
                <div className='secion_img'>                  
                    <div className='img-expinicio'/>
                </div>
                      
                </Col>
                <Col sm={12} md={6}>
                        <ul className="ulexp">
                            <li className="liexp">
                                Implementación de sistemas de gestión y calidad:  BASC, FSC, ISO.
                             </li>
                            <li className="liexp">
                                Implementación de sistema de Buenas Prácticas Agricolas- BPA y Global GAP
                            </li>
                            <li className="liexp">
                                Estudios de suelos con fines productivos
                            </li>
                            <li className="liexp">
                                Estudios de caracterización de fauna y flora
                            </li>
                            <li className="liexp"> 
                                Cálculos de capacidad de carga para senderos y áreas de turismo de naturaleza y turismo rural
                            </li>
                            <li className="liexp">
                                Formulación de Planes de manejo ambiental para áreas de conservación
                            </li>
                            <li className="liexp">
                             Diseño y estructura de bases de datos geográficas
                            </li>
                            <li className="liexp">
                                Desarrollo e implementación de aplicativos móviles, Web y Deskop
                            </li>
                        </ul>
                </Col>
                {/* <Col sm={12} md={2}>
                <div class="fb-page" data-href="https://www.facebook.com/Nexos-Empresarial-SAS-1635217300053553&quot; data-tabs=&quot;timeline&quot; data-width=&quot;800&quot; data-height=&quot;400&quot; data-small-header=&quot;false&quot; data-adapt-container-width=&quot;true&quot; data-hide-cover=&quot;false&quot; data-show-facepile=&quot;false&quot;&gt;&lt;blockquote cite=&quot;https://www.facebook.com/Nexos-Empresarial-SAS-1635217300053553&quot; class=&quot;fb-xfbml-parse-ignore&quot;&gt;&lt;a href=&quot;https://www.facebook.com/Nexos-Empresarial-SAS-1635217300053553" data-tabs="timeline" data-width="300" data-height="800" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/Nexos-Empresarial-SAS-1635217300053553&quot; data-tabs=&quot;timeline&quot; data-width=&quot;800&quot; data-height=&quot;400&quot; data-small-header=&quot;false&quot; data-adapt-container-width=&quot;true&quot; data-hide-cover=&quot;false&quot; data-show-facepile=&quot;false&quot;&gt;&lt;blockquote cite=&quot;https://www.facebook.com/Nexos-Empresarial-SAS-1635217300053553&quot; class=&quot;fb-xfbml-parse-ignore&quot;&gt;&lt;a href=&quot;https://www.facebook.com/Nexos-Empresarial-SAS-1635217300053553" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/Nexos-Empresarial-SAS-1635217300053553&quot; data-tabs=&quot;timeline&quot; data-width=&quot;800&quot; data-height=&quot;400&quot; data-small-header=&quot;false&quot; data-adapt-container-width=&quot;true&quot; data-hide-cover=&quot;false&quot; data-show-facepile=&quot;false&quot;&gt;&lt;blockquote cite=&quot;https://www.facebook.com/Nexos-Empresarial-SAS-1635217300053553&quot; class=&quot;fb-xfbml-parse-ignore&quot;&gt;&lt;a href=&quot;https://www.facebook.com/Nexos-Empresarial-SAS-1635217300053553">Nexos Empresarial SAS</a></blockquote></div>
                </Col> */}
            </Row>
       </Container>
    </div>
    );
}

export default ProInicioN;