import React, {useEffect, useState } from 'react';
import '../css/ServicioN.css';
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from 'react-ga';


const  ServiciosN = () => {

    useEffect(() => {
        ReactGA.pageview('/servicios');
      }, []);

    return(
    
        <div className="serviciosn">

            <div className='img-servicioshead'/>
           
            <Container>
            <Row className="justify-content-center"> 
                <Col className="title-servicios"  md={12} > 
                    <h2>Servicios administrativos</h2>
                    <hr className='separator'/>
                </Col>  
            </Row>
                <Row className="justify-content-center">     
                       
                    <Col className='container-servicios'  md={6}  >
                        <div className='ingresoder'>
                           
                           
                            <div className='cont-servicios'>
                                <p>
                                    Ofrecemos soluciones innovadoras mejorando el análisis de la información para la toma de decisiones de su empresa, enfocados en el mejoramiento continuo de los procesos y en la optimización de los recursos.
                                </p>
                                <ul className="list-service">
                                    <li>
                                        Sistemas de Gestión y Calidad: Certificación ISO, FSC, PEFC y BASC. 
                                    </li>
                                    <li>
                                        Desarrollo de aplicativos (software) para gestión y administración de información y procesamiento de datos.
                                    </li>
                                    <li>
                                        Asistencia en legislación laboral.
                                    </li>
                                    <li>
                                        Generación de capacidades: Capacitación en mejoramiento continuo a nivel administrativo y organizacional.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col className='container-servicios'  md={6} >
                        <div className='secion_imgadmin'>                  
                            <div className='img-admin'/>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center"> 
                    <Col className="title-servicios"  md={12} > 
                         <h2>Servicios ambientales</h2>
                         <hr className='separator'/>
                    </Col>  
                </Row>
                <Row className="justify-content-center">
                    <Col className='container-servicios' xs={{span:12, order: 2  }}     md={{span:6, order: 0}} >
                        <div className='secion_imgadmin'>                  
                                <div className='img-ambiental'/>
                        </div>
                    </Col>
                    <Col className='container-servicios' xs={{span:12, order: 1 }}  md={{ span:6, order: 1 }} >
                 
                        <div className='ingresoizqa'>
                                                   
                                <p>
                                    Somos su aliado el cumplimiento de la legislación ambiental y los estudios técnicos que la soporten.  
                                </p>
                                <ul className="list-service">
                                <li>
                                    Estudios y análisis de información: suelos, cobertura de la tierra, humedales, agua superficial y subterránea, Ecosistemas y Biodiversidad.
                                </li>
                                <li>
                                    Sistemas de información geográfica: Bases de datos, Cartografía digital, Geodesia, Topografía y fotografías aéreas.
                                </li>
                                <li>
                                    Trámites Ambientales: Licencia ambiental, concesiones de agua superficial y subterránea, permisos de aprovechamiento forestal, permisos de vertimientos
                                </li>
                                <li>
                                    Estudios de impacto ambiental
                                </li>
                                <li>
                                    Educación Ambiental: Programas de educación ambiental en procesos de recursos naturales
                                </li>
                                <li>
                                    Formulación, desarrollo e implementación de instrumentos de planificación: planes de manejo y ordenamiento, planes de aprovechamiento forestal y planes de gestión ambiental. 
                                </li>
                                <li>
                                    Interventorías y auditorías internas: Técnicas, ambientales, legales y administrativas.
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center"> 
                    <Col className="title-servicios"  md={12} > 
                        <h2>Servicios sociales</h2>
                        <hr className='separator'/>
                    </Col>  
                </Row>
                <Row className="justify-content-center">
                <Col className='container-servicios'  md={6}  >
                        <div className='ingresoder'>
                           
                            <p>
                                Ofrecemos soluciones innovadoras mejorando el análisis de la información para la toma de decisiones de su empresa, enfocados en el mejoramiento continuo de los procesos y en la optimización de los recursos.
                            </p>
                        <ul className="list-service">
                            <li>
                                Estudios de impacto social y cultural
                            </li>
                            <li>
                                Caracterizaciones socioeconómicas de actores
                            </li>
                            <li>
                                Participación ciudadana  
                            </li>
                            <li>
                                Fortalecimiento comunitario
                            </li>
                        </ul>
                        </div>
                    </Col>
                    <Col className='container-servicios'  md={6} >
                        <div className='secion_imgadmin'>                  
                                <div className='img-social'/>
                        </div>
                    </Col>
                </Row>
            </Container>
          
         
        </div>
    )
    
    }
    
    export default ServiciosN;