import React, {useEffect, useState } from 'react';
import '../css/ContacN.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form'
import emailjs from 'emailjs-com'
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert'
import ReactGA from 'react-ga';

const  ContactN = () => {

    const [show, setShow] = React.useState(false);
    const [show2, setShow2] = React.useState(false);

   const sendEmail = (e) =>{
       console.log(e);
	    e.preventDefault();
	   
		emailjs.sendForm('service_ik93jmc','template_7lqke25', e.target, 'user_xBSHF1zfievfDuHSZaGWs')
		.then((response) => {
            
            setShow(true);
            setShow2(false);
				   console.log('SUCCESS!', response.status, response.text);
				//    setContact(frmContact);
				//    setShowMessage(true);
		}, (err) => {
				   console.log('FAILED...', err);
                   setShow(false);
                   setShow2(true);
		});
        e.target.reset();
   }

   useEffect(() => {
    ReactGA.pageview('/contactenos');
  }, []);

//    Nombre de usuario de servidor de correo: info@nexosempresarial.com
// Servidor de correo entrante: nexosempresarial.com
// Servidor de correo saliente: nexosempresarial.com (el servidor requiere autenticación)
// Protocolos de correo entrante soportados: POP3, IMAP
// Protocolos de correo saliente soportados: SMTP
    return(
    
        <div>
           <iframe className='map-contact' title="ubicacion de la empresa" 
           src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63722.49243819325!2d-76.54039155215864!3d3.433678917770507!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e30a665affb745b%3A0xa5d5239686bd8fe7!2snexos%20empresarial!5e0!3m2!1ses!2sco!4v1617486908342!5m2!1ses!2sco"></iframe>
 
           
            <Container className='container-about'>
            <Row className="justify-content-center"> 
                    <Col className="title-contacto"  md={12} > 
                         <h2>Contactenos</h2>
                         <hr className='separator'/>
                    </Col>  
                </Row>
                <Row className="justify-content-center">
                    <Col xs={12} md={6}>
                    <div className='secion_imgcontacte'>                  
                            <div className='img-contac'/>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <div className='cont-contac'>
                            <div className='ingresoizqa'>                      
                            <p>info@nexosempresarial.com</p>
                            <p>Tel. (+60-2) 4845575 </p>
                            <p>Movil (+57-2) 3006022627 / 3113555905</p>
                            <p>Carrera 4 N.10-44 oficina 10-06 Edificio Plaza de Cayzedo</p>
                            <p>Cali – Colombia</p>                          
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                <Col xs={12}>
               
                    <h2>Enviar Mensaje</h2>
                        <hr className='separator'/>

                    <Form onSubmit={sendEmail}>
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>*Email:</Form.Label>
                            <Form.Control name="email" type="email" placeholder="name@example.com" required />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlInput2">
                            <Form.Label>*Nombre:</Form.Label>
                            <Form.Control name="nameuser" type="text" placeholder="" required/>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlInput3">
                            <Form.Label>*Asunto:</Form.Label>
                            <Form.Control name="title" type="text" placeholder="" required/>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlInput4">
                            <Form.Label>Telefono:</Form.Label>
                            <Form.Control name="telefono" type="number"  />
                        </Form.Group>
                        
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>*Mensaje:</Form.Label>
                            <Form.Control name="message" as="textarea" rows={3} required/>
                        </Form.Group>
                            <div className="btn-service">
                                <Button type="submit" variant="warning">Enviar</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Alert show={show} variant="success">
                    Mensaje enviado con exito!
                </Alert>
                <Alert show={show2} variant="danger">
                    Error de Envio!
                </Alert>
               
            </Container>

         
        </div>
    )
    
    }
    
    export default ContactN;