import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import img1 from '../img/inicio/baner1.jpg';
import img2 from '../img/inicio/baner2.jpg';
import img3 from '../img/inicio/baner3.jpg';



import '../css/CarouselN.css';


const  CarouselN = () => {

    return ( 
     
    <div className="CarouselN">
    
        <Carousel >
            <Carousel.Item>
                <div className = "carrouselanime">
                        <p className = "animetitle">
                            Servicios integrales para su organización
                        </p>
                    <div className="carousel-button">
                        <p className = "animesubtitle">
                            Su opción segura y oportuna
                        </p>
                    </div>
                </div>
                <img
                className="d-block w-100"
                src={img1}
                alt="primer slide"
                height="500"
                />
               
            </Carousel.Item>
             <Carousel.Item>
              <div className = "carrouselanime">
                    <p className = "animetitle">
                        Servicios integrales para su organización
                    </p>
                    <div className="carousel-button">
                        <p className = "animesubtitle">
                            Acompañamiento integral
                        </p>                  
                    </div>
                </div>
                <img
                className="d-block w-100"
                src={img2}
                alt="Second slide"
                height="500"
                />
            </Carousel.Item>
            <Carousel.Item>
              <div className = "carrouselanime">
                    <p className = "animetitle">
                        Servicios integrales para su organización
                    </p>
                    <div className="carousel-button">
                        <p className = "animesubtitle">
                            Calidad e innovación a su alcance
                        </p>                  
                    </div>
                </div>
                <img
                className="d-block w-100"
                src={img3}
                alt="Second slide"
                height="500"
                />
            </Carousel.Item>
        </Carousel>
    </div>
    );
}

export default CarouselN;