import React, {useEffect, useState } from 'react';
import '../css/ProyectoN.css';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck'
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav';
import Col from 'react-bootstrap/Col'
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from 'react-ga';
let proyecto2013 = require('../document/proyecto2013.json');
let proyecto2014 = require('../document/proyecto2014.json');
let proyecto2015 = require('../document/proyecto2015.json');
let proyecto2016 = require('../document/proyecto2016.json');
let proyecto2017 = require('../document/proyecto2017.json');
let proyecto2018 = require('../document/proyecto2018.json');
let proyecto2019 = require('../document/proyecto2019.json');
let proyecto2020 = require('../document/proyecto2020.json');
let proyecto2021 = require('../document/proyecto2021.json');




const ProyectN = () =>{

    useEffect(() => {
        ReactGA.pageview('/proyectos');
      }, []);

    return (

<div className="ProyectN">



    <div className='img-proyect'/>
       
<h2>Experiencia Realizadas</h2>

    <hr className='separator'/>


        <Container className="containerproyecto">

        
        <Tab.Container id="left-tabs-example" defaultActiveKey="uno">
            <Row>
                <Col sm={1}>
                <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                    <Nav.Link eventKey="uno">2021</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link eventKey="dos">2020</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link eventKey="tres">2019</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link eventKey="cuatro">2018</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link eventKey="cinco">2017</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link eventKey="seis">2016</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link eventKey="siete">2015</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link eventKey="ocho">2014</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link eventKey="nueve">2013</Nav.Link>
                    </Nav.Item>
                </Nav>
                </Col>
                <Col sm={11}>
                    <Tab.Content>
                        <Tab.Pane eventKey="uno">
                            <CardDeck className="justify-content-center">
                                {
                                    proyecto2021.map((item) => {
                                                      
                                    return(

                                        <div key={item.id}>
                                            <Card className="mb-5"  style={{ width: '20rem', height:'20rem' }}>
                                                <Card.Body>
                                                    <Card.Title>{item.Cliente}</Card.Title>
                                                    <Card.Text>                                                        
                                                        {item.name}
                                                    </Card.Text>
                                                    <p className="cardpie">
                                                        {item.pais}
                                                        <br/>
                                                        {item.fecha}
                                                    </p>                                                                                               
                                                </Card.Body>
                                                <Card.Footer>
                                                <Button variant="success" href={`/proyecto/${item.id}`}>Ver Mas</Button>
                                                {/* <Link to={`/proyecto/${item.id}`}> {item.id} </Link> */}
                                                </Card.Footer>
                                            </Card>
                                        </div>
                                        )
                                    })
                                }
                            </CardDeck>
                        </Tab.Pane>
                        
                        <Tab.Pane eventKey="dos">
                            <CardDeck className="justify-content-center">
                                {
                                    proyecto2020.map((item) => {
                                                      
                                    return(

                                        <div key={item.id}>
                                            <Card className="mb-5"  style={{ width: '20rem' , height:'20rem' }}>
                                                <Card.Body>
                                                    <Card.Title>{item.Cliente}</Card.Title>
                                                    <Card.Text>                                                        
                                                        {item.name}
                                                    </Card.Text>
                                                    <p className="cardpie">
                                                        {item.pais}
                                                        <br/>
                                                        {item.fecha}
                                                    </p>    
                                                </Card.Body>
                                                <Card.Footer>
                                                <Button variant="success" href={`/proyecto/${item.id}`}>Ver Mas</Button>
                                                {/* <Link to={`/proyecto/${item.id}`}> {item.id} </Link> */}
                                                </Card.Footer>
                                            </Card>
                                        </div>
                                        )
                                    })
                                }
                            </CardDeck>
                        </Tab.Pane>

                        <Tab.Pane eventKey="tres">
                            <CardDeck className="justify-content-center">
                                {
                                    proyecto2019.map((item) => {
                                                      
                                    return(

                                        <div key={item.id}>
                                            <Card className="mb-5"  style={{ width: '20rem', height:'20rem' }}>
                                                <Card.Body>
                                                    <Card.Title>{item.Cliente}</Card.Title>
                                                    <Card.Text>                                                        
                                                        {item.name}
                                                    </Card.Text>
                                                    <p className="cardpie">
                                                        {item.pais}
                                                        <br/>
                                                        {item.fecha}
                                                    </p>    
                                                </Card.Body>
                                                <Card.Footer>
                                                <Button variant="success" href={`/proyecto/${item.id}`}>Ver Mas</Button>
                                                {/* <Link to={`/proyecto/${item.id}`}> {item.id} </Link> */}
                                                </Card.Footer>
                                            </Card>
                                        </div>
                                        )
                                    })
                                }
                            </CardDeck>
                        </Tab.Pane>

                        <Tab.Pane eventKey="cuatro">
                            <CardDeck className="justify-content-center">
                                {
                                    proyecto2018.map((item) => {
                                                      
                                    return(

                                        <div key={item.id}>
                                            <Card className="mb-5"  style={{ width: '20rem', height:'20rem' }}>
                                                <Card.Body>
                                                    <Card.Title>{item.Cliente}</Card.Title>
                                                    <Card.Text>                                                        
                                                        {item.name}
                                                    </Card.Text>
                                                    <p className="cardpie">
                                                        {item.pais}
                                                        <br/>
                                                        {item.fecha}
                                                    </p>    
                                                </Card.Body>
                                                <Card.Footer>
                                                <Button variant="success" href={`/proyecto/${item.id}`}>Ver Mas</Button>
                                                {/* <Link to={`/proyecto/${item.id}`}> {item.id} </Link> */}
                                                </Card.Footer>
                                            </Card>
                                        </div>
                                        )
                                    })
                                }
                            </CardDeck>
                        </Tab.Pane>

                        <Tab.Pane eventKey="cinco">
                            <CardDeck className="justify-content-center">
                                {
                                    proyecto2017.map((item) => {
                                                      
                                    return(

                                        <div key={item.id}>
                                            <Card className="mb-5"  style={{ width: '20rem', height:'20rem' }}>
                                                <Card.Body>
                                                    <Card.Title>{item.Cliente}</Card.Title>
                                                    <Card.Text>                                                        
                                                        {item.name}
                                                    </Card.Text>
                                                    <p className="cardpie">
                                                        {item.pais}
                                                        <br/>
                                                        {item.fecha}
                                                    </p>    
                                                </Card.Body>
                                                <Card.Footer>
                                                <Button variant="success" href={`/proyecto/${item.id}`}>Ver Mas</Button>
                                                {/* <Link to={`/proyecto/${item.id}`}> {item.id} </Link> */}
                                                </Card.Footer>
                                            </Card>
                                        </div>
                                        )
                                    })
                                }
                            </CardDeck>
                        </Tab.Pane>

                        <Tab.Pane eventKey="seis">
                            <CardDeck className="justify-content-center">
                                {
                                    proyecto2016.map((item) => {
                                                      
                                    return(

                                        <div key={item.id}>
                                            <Card className="mb-5"  style={{ width: '20rem', height:'20rem' }}>
                                                <Card.Body>
                                                    <Card.Title>{item.Cliente}</Card.Title>
                                                    <Card.Text>                                                        
                                                        {item.name}
                                                    </Card.Text>
                                                    <p className="cardpie">
                                                        {item.pais}
                                                        <br/>
                                                        {item.fecha}
                                                    </p>    
                                                </Card.Body>
                                                <Card.Footer>
                                                <Button variant="success" href={`/proyecto/${item.id}`}>Ver Mas</Button>
                                                {/* <Link to={`/proyecto/${item.id}`}> {item.id} </Link> */}
                                                </Card.Footer>
                                            </Card>
                                        </div>
                                        )
                                    })
                                }
                            </CardDeck>
                        </Tab.Pane>

                        <Tab.Pane eventKey="siete">
                            <CardDeck className="justify-content-center">
                                {
                                    proyecto2015.map((item) => {
                                                      
                                    return(

                                        <div key={item.id}>
                                            <Card className="mb-5"  style={{ width: '20rem', height:'20rem' }}>
                                                <Card.Body>
                                                    <Card.Title>{item.Cliente}</Card.Title>
                                                    <Card.Text>                                                        
                                                        {item.name}
                                                    </Card.Text>
                                                    <p className="cardpie">
                                                        {item.pais}
                                                        <br/>
                                                        {item.fecha}
                                                    </p>    
                                                </Card.Body>
                                                <Card.Footer>
                                                <Button variant="success" href={`/proyecto/${item.id}`}>Ver Mas</Button>
                                                {/* <Link to={`/proyecto/${item.id}`}> {item.id} </Link> */}
                                                </Card.Footer>
                                            </Card>
                                        </div>
                                        )
                                    })
                                }
                            </CardDeck>
                        </Tab.Pane>

                        <Tab.Pane eventKey="ocho">
                            <CardDeck className="justify-content-center">
                                {
                                    proyecto2014.map((item) => {
                                                      
                                    return(

                                        <div key={item.id}>
                                            <Card className="mb-5"  style={{ width: '20rem', height:'20rem' }}>
                                                <Card.Body>
                                                    <Card.Title>{item.Cliente}</Card.Title>
                                                    <Card.Text>                                                        
                                                        {item.name}
                                                    </Card.Text>
                                                    <p className="cardpie">
                                                        {item.pais}
                                                        <br/>
                                                        {item.fecha}
                                                    </p>    
                                                </Card.Body>
                                                <Card.Footer>
                                                <Button variant="success" href={`/proyecto/${item.id}`}>Ver Mas</Button>
                                                {/* <Link to={`/proyecto/${item.id}`}> {item.id} </Link> */}
                                                </Card.Footer>
                                            </Card>
                                        </div>
                                        )
                                    })
                                }
                            </CardDeck>
                        </Tab.Pane>

                        <Tab.Pane eventKey="nueve">
                            <CardDeck className="justify-content-center">
                                {
                                    proyecto2013.map((item) => {
                                                      
                                    return(

                                        <div key={item.id}>
                                            <Card className="mb-5"  style={{ width: '20rem', height:'20rem' }}>
                                                <Card.Body>
                                                    <Card.Title>{item.Cliente}</Card.Title>
                                                    <Card.Text>                                                        
                                                        {item.name}
                                                    </Card.Text>
                                                    <p className="cardpie">
                                                        {item.pais}
                                                        <br/>
                                                        {item.fecha}
                                                    </p>    
                                                </Card.Body>
                                                <Card.Footer>
                                                <Button variant="success" href={`/proyecto/${item.id}`}>Ver Mas</Button>
                                                {/* <Link to={`/proyecto/${item.id}`}> {item.id} </Link> */}
                                                </Card.Footer>
                                            </Card>
                                        </div>
                                        )
                                    })
                                }
                            </CardDeck>
                        </Tab.Pane>


                    </Tab.Content>
                </Col>
            </Row>
            </Tab.Container>
       </Container>
      
    </div>
    );
}

export default ProyectN;