import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import logo from '../img/footer/nexosblancoletra.png';
import aliado from '../img/footer/FSCaliado.png';
import '../css/FooterN.css'

const  FooterN = () => {

return(

    <footer className="footer">
            <Container fluid>          
            <Row  className="justify-content-center">
                <Col sm={6} md={3}>

                    <img className="logofooter" src={logo} alt="logo"/>
                </Col>
                <Col sm={6} md="auto">
                    <h4>Menu</h4>
                    <ul >
                        <li><a  href="/">Inicio</a></li>
                        <li><a  href="/nosotros">Nosotros</a></li>
                        <li><a  href="/">Servicios</a></li>
                        <li><a  href="/proyectos">Experiencia</a></li>
                        <li><a  href="/contactenos">Contactenos</a></li>
                        {/* <li><a  href="/contactenos">Galeria</a></li> */}
                    </ul>
                </Col>
                <Col sm={6} md="auto">
                    <h4><a  href={`/proyectos`}>Servicios</a></h4>
                    <ul >
                        <li>Administrativos</li>
                        <li>Ambientales</li>
                        <li>Sociales</li>
                    </ul>
                </Col>       
                <Col sm={6} md="auto">
                <h4><a href={`/contactenos`}>Contactenos</a></h4>
                    <ul >
                        <li>Movil (+57) 3006022627</li>
                        <li>(+57) 3113555905</li>
                        <li>Tel. (+60-2) 4845575</li>
                        <li>info@nexosempresarial.com</li>
                        <li>Santiago de Cali D.E – Colombia</li>
                        <li><a href='http://webmail.nexosempresarial.com/Mondo/lang/sys/login.aspx'>Correo Empresa</a></li>
                      
                    </ul>
                </Col>
                <Col sm={6} md={3}>
                    <h4>Nuestro Aliado</h4>
                    <img className="logoaliado" src={aliado} alt="aliado"/>
                </Col>
            </Row>
           
            </Container>
    </footer>

)

}

export default FooterN;