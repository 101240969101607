import React from 'react';
import '../css/inicioN.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CarouselN from './CarouselN';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ProInicioN from './ProInicioN';
import Clientes from './Clientes';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import Col from 'react-bootstrap/Col'
import icon1 from '../icon/admin.png'
import icon2 from '../icon/ambintal.png'
import icon3 from '../icon/social.png'

const InicioN = () => {

    return (

        <div className='inicioN'>

            <section>
                <CarouselN />
            </section>
            <Container >
                <section className="card-service">
                    <div className="title-card">
                        <h2>Servicios</h2>
                        <hr className='separator' />
                    </div>
                    <Row className="justify-content-center">
                        <Col sm={12} md={4} xl={4} >
                            <Card style={{ width: '22rem', height: '22rem' }}>
                                <div class="thumbnail" style={{ width: '22rem', height: '22rem' }}>

                                    <div className="cardhover">
                                        <div>
                                            <ul>
                                                <li>Sistemas de Gestión y Calidad: Certificación ISO, FSC, PEFC y BASC.</li>
                                                <li>Desarrollo de aplicativos (software) para gestión y administración de información y procesamiento de datos.</li>
                                                <li>Asistencia en legislación laboral.</li>
                                                <li>Generación de capacidades: Capacitación en mejoramiento continuo a nivel administrativo y organizacional.</li>

                                            </ul>
                                        </div>
                                    </div>

                                    <div className="div-circulo">
                                        <div className="div-card">
                                            <span className="icon-card">
                                                <i className="fas fa-chart-bar"></i>
                                                <img src={icon1} />
                                            </span>
                                        </div>
                                    </div>
                                    <Card.Body>
                                        <Card.Title>Servicios administrativos</Card.Title>
                                        <Card.Text>
                                            Ofrecemos soluciones innovadoras mejorando el análisis de la información para la toma de decisiones de su empresa, enfocados en el mejoramiento continuo de los procesos y en la optimización de los recursos.
                                        </Card.Text>
                                    </Card.Body>
                                </div>
                            </Card>
                        </Col>
                        <Col sm={12} md={4} xl={4}>
                            <Card style={{ width: '22rem', height: '22rem' }}>
                                <div class="thumbnail" style={{ width: '22rem', height: '22rem' }}>

                                    <div className="cardhover">
                                        <div>
                                            <ul>
                                                <li>Estudios y análisis de información: suelos, cobertura de la tierra, humedales, aguas, Ecosistemas y Biodiversidad.</li>
                                                <li>Sistemas de información geográfica: Bases de datos, Desarrollo de aplcaciones geográficas, Cartografía digital, Geodesia, Topografía y fotografías aéreas.</li>
                                                <li>Trámites Ambientales: Licencia ambiental, concesiones de agua superficial y subterránea...</li>


                                            </ul>
                                        </div>
                                    </div>
                                    <div className="div-circulo">
                                        <div className="div-card">
                                            <span className="icon-card">
                                                <img src={icon2} width="46px" />
                                            </span>
                                        </div>
                                    </div>

                                    <Card.Body>
                                        <Card.Title>Servicios ambientales</Card.Title>
                                        <Card.Text>
                                            Somos su aliado el cumplimiento de la legislación ambiental y los estudios técnicos que la soporten.
                                        </Card.Text>
                                    </Card.Body>
                                </div>
                            </Card>
                        </Col>
                        <Col sm={12} md={4} xl={4}>
                            <Card style={{ width: '22rem', height: '22rem' }}>
                                <div class="thumbnail" style={{ width: '22rem', height: '22rem' }}>

                                    <div className="cardhover">
                                        <div>
                                            <ul>
                                                <li>Estudios de impacto social y cultural.</li>
                                                <li>Caracterizaciones socioeconómicas de actores.</li>
                                                <li>Participación ciudadana.</li>
                                                <li>Fortalecimiento comunitario.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="div-circulo">
                                        <div className="div-card">
                                            <span className="icon-card">
                                                <img src={icon3} />
                                            </span>
                                        </div>
                                    </div>
                                    <Card.Body>
                                        <Card.Title>Servicios sociales</Card.Title>
                                        <Card.Text>
                                            Apoyamos la responsabilidad social de su organización.
                                        </Card.Text>
                                    </Card.Body>

                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <div className="btn-service">
                        <Button variant="warning" href={`/servicios`}>Ver mas</Button>
                    </div>
                </section>
            </Container>


            <section className="section-project">
                <div className="figura">
                    <div className="title-project">
                        <h2>Experiencia</h2>
                        <hr className='separator' />
                    </div>
                    <ProInicioN />
                    <div className="btn-proyect">
                        <Button variant="warning" href={`/proyectos`}>Ver Mas</Button>
                    </div>

                </div>
            </section>

            <section className="section-choose">
                <div className="figura">
                    <div className="title-project">
                        <h2>Nos eligen por</h2>
                        <hr className='separator' />
                    </div>
                    <Container className="containereligen">
                        <Row>
                            <Col md={4}>
                                <div className="diveleigen">
                                    <div className="diveleigent">
                                        <p className="pnum">01</p>
                                        <hr />
                                        <h5>CALIDAD</h5>
                                    </div>
                                    <p>
                                        Realizamos todas nuestras actividades eficientemente asegurando satisfacer las necesidades concretas. Nos aseguramos de la correcta aplicación de todos los recursos ejecutando acciones eficaces.
                                    </p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="diveleigen">
                                    <div className="diveleigent">
                                        <p className="pnum">02</p>
                                        <hr />
                                        <h5>INNOVACIÓN</h5>
                                    </div>
                                    <p>
                                        Aplicamos siempre nuevas ideas, conceptos, productos, servicios y prácticas con la intensión de ser útiles para el incremento de la productividad y la competitividad.
                                    </p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="diveleigen">
                                    <div className="diveleigent">
                                        <p className="pnum">03</p>
                                        <hr />
                                        <h5>RESPETO</h5>
                                    </div>
                                    <p>
                                        Damos consideración al conocimiento y a los diferentes puntos de vista de aquellos con quienes interactuamos, respetamos la confidencialidad de procesos en cada organización.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <div className="diveleigen">
                                    <div className="diveleigent">
                                        <p className="pnum">04</p>
                                        <hr />
                                        <h5>COMPROMISO</h5>
                                    </div>
                                    <p>
                                        Cumplimos con los objetivos, productos y tiempos acordados con el máximo de las cualidades y calidades de nuestra organización.
                                    </p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="diveleigen">
                                    <div className="diveleigent">
                                        <p className="pnum">05</p>
                                        <hr />
                                        <h5>TRANSPARENCIA</h5>
                                    </div>
                                    <p>
                                        Mostramos de manera auténtica, honesta y abierta nuestro conocimiento y experiencia en beneficio de quienes confían sus procesos en nosotros.
                                    </p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="diveleigen">
                                    <div className="diveleigent">
                                        <p className="pnum">06</p>
                                        <hr />
                                        <h5>LEALTAD</h5>
                                    </div>
                                    <p>
                                        Somos leales a quienes creen en nosotros y en lo que hacemos, cumplimos con lo acordado respetando los intereses y siempre generando la mejor solución.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>


            <section className="section-count">
                <Container>
                    <Row className="justify-content-center">
                        <Col sm={12} md={10}></Col>
                        <div className="justify-content-center circule-card-col">
                            <div className="card-col">
                                <Card className="card-count">
                                    {/* <div className="div-iconcard">      
                                    <span className="icon-count">
                                    <i className="fas fa-hands-helping"></i>
                                    </span>
                                </div>                      */}
                                    <div className="div-countupcircle">

                                        <h5>
                                            <CountUp end={10} duration={10}>
                                                {({ countUpRef, start }) => (
                                                    <VisibilitySensor onChange={start} delayedCall={true}>
                                                        <span ref={countUpRef} />
                                                    </VisibilitySensor>
                                                )}
                                            </CountUp>
                                        </h5>

                                        <h4>Años de Experiencia</h4>
                                    </div>
                                </Card>
                            </div>
                        </div>
                        <div className="contenedor-countup">
                            <div className="div-countup">
                                <h5>
                                    <CountUp end={52} duration={7}>
                                        {({ countUpRef, start }) => (
                                            <VisibilitySensor onChange={start} delayedCall>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>
                                </h5>
                                <h4>Empresas asesoradas</h4>
                            </div>
                            <div className="div-countup">
                                <h5>
                                    <CountUp end={35} duration={5}>
                                        {({ countUpRef, start }) => (
                                            <VisibilitySensor onChange={start} delayedCall>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>
                                </h5>
                                <h4>Proyectos nacionales</h4>
                            </div>
                            <div className="div-countup">
                                <h5>
                                    <CountUp end={20} duration={6}>
                                        {({ countUpRef, start }) => (
                                            <VisibilitySensor onChange={start} delayedCall>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>
                                </h5>
                                <h4>Proyectos internacionales</h4>
                            </div>
                        </div>

                    </Row>
                </Container>
            </section>

            <section>
                <div>
                    <div className="title-card">
                        <h2>Clientes</h2>
                        <hr className='separator' />
                        <h4>Importantes organizaciones confían en nosotros</h4>
                    </div>
                    <Clientes />
                </div>
            </section>


        </div>
    );
}

export default InicioN;