import React, { useEffect, useState } from 'react';

import logo1 from './assets/img/inicio/nexosblanco.png';
import './assets/css/App.css';
import Container from 'react-bootstrap/Container';
import InicioN from './assets/componets/InicioN';
import ProyectN from './assets/componets/ProyectN';
import AcercaN from './assets/componets/AcercaN';
import ProyectId from './assets/componets/ProyectId';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/MenuN.css';
import FooterN from './assets/componets/FooterN'; 
import ContactN from './assets/componets/ContactN';
import ServiciosN from './assets/componets/SeviciosN';
import icon1 from '../src/assets/icon/insta.png'
import icon2 from '../src/assets/icon/face.png'
import icon3 from '../src/assets/icon/twi.png'
import ReactGA from 'react-ga';

import {
    BrowserRouter,
    Switch,
    Route,
    Redirect
  } from "react-router-dom";


function App() {

  const [offset, setOffset] = useState(0);


  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset)
    
    }
  }, []);
  // const numoffset = offset;
  useEffect(() => {
    ReactGA.initialize('G-GH5ECF297T');
  }, []);
  

  const menucss1 = 'Menu';
  const menucss2 = 'Menu2';
  const menutopcss1 = 'MenuN-HeaderTop';
  const menutopss2 = 'MenuN-HeaderTop2';


  return ( 
    <div className="app">
      <BrowserRouter>  
      
      <div className={offset < 400 ?  menucss1 : menucss2}>
          <div className={offset < 400 ?  menutopcss1 : menutopss2}>
            
            <Container>
              <Row> 
                <Col>
                  <div className="menu-iconredes">                 
                        <span className="menu-falink">
                          <a href='https://instagram.com/nexosempresarialsas?utm_medium=copy_link' target="_blank" rel="noreferrer">
                          <img src={icon1} width= "24px" height="24px"/>
                          </a>
                        </span>               
                        <span className="menu-falink1">
                        <a href='https://www.facebook.com/Nexos-Empresarial-SAS-1635217300053553' target="_blank" rel="noreferrer">
                        <img src={icon2} width= "24px" height="24px" />
                          </a>
                        </span>                
                        <span className="menu-falink1">
                          <a href='https://twitter.com/nexosempresaria' target="_blank" rel="noreferrer">
                          <img src={icon3} width= "24px" height="24px" />
                          </a>
                        </span>                                  
                    </div>
                </Col>
                <Col>
                  <div className="menu-info">
                    <span className="menu-fa">
                      <i className="fas fa-phone-alt"> 3006022627 / 3113555905</i>
                      </span> 
                      <span className="menu-fa info-email">
                      <i className="fas fa-envelope"> info@nexosempresarial.com</i> 
                      </span> 
                  </div>                             
                 </Col>
                 </Row>
            </Container>              
          </div>

          <hr className="menu-hr"/>

          <Navbar collapseOnSelect className="Menu-Navbar navbar-dark" expand="lg" >
          
          {/* <Container> */}
          
          <img className="logo" src={logo1} alt="logo"/>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="ml-auto">
                      <Nav.Link href="/">Inicio</Nav.Link>
                      <Nav.Link  href="/nosotros">Nosotros</Nav.Link>
                      <Nav.Link  href="/servicios">Servicios</Nav.Link>
                      <Nav.Link  href="/proyectos">Experiencia</Nav.Link>                   
                      <Nav.Link  href="/contactenos">Contactenos</Nav.Link>
                      {/* <Nav.Link  href="/contactenos">Galeria</Nav.Link> */}
                      {/* <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                          <NavDropdown.Item >Action</NavDropdown.Item>
                          <NavDropdown.Item >Another action</NavDropdown.Item>
                          <NavDropdown.Item >Something</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item >Separated link</NavDropdown.Item>
                      </NavDropdown> */}
                  </Nav>
              </Navbar.Collapse>
          {/* </Container> */}
          </Navbar>
        </div>
        
  
        <Switch>

          <Route path="/" exact>
            <InicioN />
          </Route>
          <Route path="/proyecto/:id" component={ProyectId} >
            {/* <ProyectId/> */}
          </Route>
          <Route path="/proyectos">
            <ProyectN />
          </Route>
          <Route path="/contactenos">
            <ContactN />
          </Route>
          <Route path="/servicios">
            <ServiciosN />
          </Route>
          <Route path="/nosotros">
            <AcercaN />
          </Route>
          <Redirect to='/' />
        </Switch>
       
      
     </BrowserRouter>

        <FooterN/>
     </div>
  );
}

export default App;
